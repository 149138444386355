import React from "react"
import PropTypes from 'prop-types';
import {  withStyles } from "@material-ui/core"
import Layout from '../components/layout';
import Helmet from 'react-helmet';

const styles = theme => ({
});

function Content(props) {
  return (
    <Layout>
      <Helmet>
          <title>Contact Us - QHFS Limited</title>
      </Helmet>
      <h2>Contact QHFS</h2>

      <p>Call us on <a href="tel:01626824988">01626 824988</a></p>
      <p>Fax us on 01626 821963</p>
      <p>Email us at <a href="mailto:mail@qhfs.co.uk">mail@qhfs.co.uk</a></p>

      <p>Write to us at Orchard House,Liverton, Newton Abbot, Devon, TQ12 6HS.</p>

      <p>Email communications are not secure, for this reason (QHFS Ltd) cannot guarantee the security of the email or its contents or that it remains virus free once sent.</p>
    </Layout>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);